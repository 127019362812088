import React from 'react';

import { useSelector } from 'react-redux';

import { ImpersonationInfo } from '@ha/api/v2/types/UserInformation';
import { AUTH_IMPERSONATE_END_URL } from '@ha/auth/common/routes';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useIntl } from 'ha/i18n';
import { useServices } from 'ha/services';

import { clearUserData } from '../clearUserData';
import { getUserInformation } from '../selectors';

interface ChildrenProps {
  impersonatingUser: ImpersonationInfo | undefined;
  isImpersonatedByAdmin: boolean;
  onStop: () => void;
}

interface Props {
  children: React.FunctionComponent<ChildrenProps>;
}

export const useImpersonationInformation = () => {
  const { urlResolver } = useIntl();
  const { cookie } = useServices();

  const userInformation = useSelector(getUserInformation);
  const isImpersonatedByManagedGroupOwner =
    userInformation &&
    userInformation.managedGroup &&
    userInformation.impersonation &&
    userInformation.managedGroup.ownerUuid ===
      userInformation.impersonation.uuid;

  const stopImpersonation = React.useCallback(async () => {
    await fetch(AUTH_IMPERSONATE_END_URL).catch(error => {
      reportError(new Error('Failed impersonation stop', { cause: error }));
    });

    clearUserData(cookie);

    window.location.assign(
      isImpersonatedByManagedGroupOwner
        ? urlResolver.getManagedUsersDashboardUrl()
        : urlResolver.getAdminPanelUrl(),
    );
  }, [urlResolver, cookie, isImpersonatedByManagedGroupOwner]);

  return {
    impersonatingUser: userInformation?.impersonation,
    isImpersonatedByAdmin:
      Boolean(userInformation?.impersonation) &&
      !isImpersonatedByManagedGroupOwner,
    onStop: stopImpersonation,
  };
};

export function Impersonation({ children }: Props) {
  const impersonationInformation = useImpersonationInformation();

  return children(impersonationInformation);
}
