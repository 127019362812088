import { FetchClient } from 'ha/helpers/FetchClient';
import { preprocessResponse } from 'ha/utils/preprocessResponse';

export interface CreateManagedGroupUserPayload {
  email: string;
  phone?: string;
  // The password is optional.
  // If not provided, the IDP will generate a random password automatically.
  password?: string;
  profile: {
    firstName: string;
    lastName: string;
    birthDay?: number;
    birthMonth?: number;
    birthYear?: number;
    countryOfResidence?: string; // country code
    gender?: 'female' | 'male' | 'other';
    language?: string;
    spokenLanguages?: string[];
    nationality?: string; // country code
    location?: string;
    occupation?: 'student' | 'working professional' | 'other';
    occupationDetail?: string;
  };
}

interface CreateManagedGroupUserResponse {
  alertID: string;
}

interface ImpersonationInitPayload {
  impersonateeUserUUID: string;
  goTo?: string;
}

interface ImpersonationInitResponse {
  token: string;
}

export function makeIdpService(fetchClient: FetchClient) {
  return {
    createManagedGroupUser: (
      managedGroupUUID: string,
      body: CreateManagedGroupUserPayload,
    ): Promise<CreateManagedGroupUserResponse> =>
      fetchClient
        .fetch(`/idp/v1/identity/managed-group/${managedGroupUUID}/user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(preprocessResponse),
    impersonationInit: (
      body: ImpersonationInitPayload,
    ): Promise<ImpersonationInitResponse> =>
      fetchClient
        .fetch('/idp/v1/oidc/impersonation/init', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(preprocessResponse),
  };
}
