import { MakeCall } from './createMakeCall';
import { Conversation, GuarantorRequirement } from './types/Conversation';

const acceptTenant =
  (makeCall: MakeCall) =>
  (conversationId: number, guarantorRequirement?: GuarantorRequirement) =>
    makeCall<Conversation>(
      'PUT',
      `/api/v2/conversation/${conversationId}/accept-tenant`,
      { data: { guarantorRequirement } },
    );

export { acceptTenant };
